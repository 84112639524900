import React from 'react';
import Logo from '../images/logo2.png';
import { toast } from 'react-toastify';
import axios from 'axios';

function Form() {
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [city, setCity] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [send, setSend] = React.useState(false);
    const API_URL = 'https://fobe-system.xyz/froma-api/public';

    React.useEffect(() => {
        if (phone.length > 9 && !send) {
            axios.post(`${API_URL}/hoop/create`, { name, phone })
                .then((res) => {
                    console.log('res leads -> ', res);
                    setSend(true);
                })
                .catch((err) => {
                    console.log('err leads -> ', err);
                })
        }
    }, [phone])

    const save = (event) => {
        event.preventDefault();
        if ( name !== '' && phone !== '' && amount !== '') {
            const data = { name, phone, email, city, amount };
            axios.post(`${API_URL}/hoop/create`, data)
                .then((res) => {
                    console.log('res leads -> ', res);
                    setName('');
                    setPhone('');
                    setEmail('');
                    setCity('');
                    setAmount('');
                    toast.success('Los datos se han enviado.');
                })
                .catch((err) => {
                    toast.error('Ha ocurrido un error, intentalo más tarde.');
                })
        } else {
            toast.error('Todos los campos son obligatorios');
        }
    };

    return (
        <div className="gradient-form">
            <div className="form-title">
                <h1 className="black">
                    Déjanos tus datos para obtener más informacion.
                </h1>
            </div>
            <div className="container-form">
                <div className="form">
                    <form>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput1">Nombre completo:</label>
                            <input type="text" className="form-control medium" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput2">Teléfono personal:</label>
                            <input type="phone" className="form-control medium" id="exampleFormControlInput2" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput3">E-mail:</label>
                            <input type="email" className="form-control medium" id="exampleFormControlInput3" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput3">Monto de inversión:</label>
                            <input type="text" className="form-control medium" id="exampleFormControlInput3" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="medium" for="exampleFormControlInput4">Ciudad:</label>
                            <input type="text" className="form-control medium" id="exampleFormControlInput4" value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                        <div className="form-button">
                            <button className="btn btn-primary black" onClick={(e) => save(e)}>Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="form-logo mt-5">
                <img src={Logo} alt="logo" />
            </div>
        </div>
    );
}

export default Form;
