import React from 'react';
import Logo from '../images/logo.png';

function Main() {
    return (
        <div className="gradient">
            <div className="container-title">
                <div className="title">
                    <h1 className="black">Menor inversión, mejor retorno, más flexibilidad.</h1>
                </div>
                <div className="title mt-5">
                    <img className="mt-5" src={Logo} alt="logo" />
                </div>
            </div>
        </div>
    );
}

export default Main;
