import React, { useRef } from 'react'
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const PrettoSlider = styled(Slider)({
    color: '#000000',
    fontFamily: 'Avenir',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
        backgroundColor: 'transparent'
    },
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        border: '5px solid #00000',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-rail': {
        opacity: 1,
        background: 'linear-gradient(90deg, rgba(193,0,236,1) 30%, rgba(120,1,218,1) 60%, rgba(79,0,205,1) 80%, rgba(49,0,195,1) 100%)',
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});


function Calculator() {
    const [total, setTotal] = React.useState(0);
    const [amount, setAmount] = React.useState(10000);
    const [months, setMonths] = React.useState(1);

    const myRef = useRef(null)
    const executeScroll1 = () => scrollToRef1(myRef)

    const scrollToRef = () => {
        window.scrollTo(0, 1400);
    };

    const scrollToRef1 = () => {
        window.scrollTo(0, 700);
    };

    const executeScroll = () => scrollToRef();

    React.useEffect(() => {
        let percentage = 0.05;
        switch (months) {
            case 1:
                percentage = 0.05;
                break;
            case 2:
                percentage = 0.10;
                break;
            default:
                percentage = 0.20;
                break;
        }
        const totalPercent = percentage * amount;
        setTotal(totalPercent + amount);
    }, [amount, months])

    React.useEffect(() => {
        setTimeout(() => {
            executeScroll1();
        }, 1500);
    }, [])

    const amountMarks = [
        {
            value: 10000,
            label: '10k'
        },
        {
            value: 20000,
            label: '20k'
        },
        {
            value: 30000,
            label: '30k'
        },
        {
            value: 40000,
            label: '40k'
        },
        {
            value: 50000,
            label: '50k'
        },
        {
            value: 60000,
            label: '60k'
        },
        {
            value: 70000,
            label: '70k'
        },
        {
            value: 80000,
            label: '80k'
        },
        {
            value: 90000,
            label: '90k'
        },
        {
            value: 100000,
            label: '100k'
        },
    ];

    const monthsMarks = [
        {
            value: 1,
            label: '3 meses',
        },
        {
            value: 2,
            label: '6 meses',
        },
        {
            value: 3,
            label: '12 meses',
        },
    ];

    function valuetext(value) {
        return `${value}°C`;
    }

    const currencyFormat = (amount) => {
        var formatter = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
        });
        return formatter.format(amount);
    };

    return (
        <div ref={myRef} id="calculator" className="calculator-background">
            <div className="container">
                <div className="w-100 h-auto mt-5 text-center">
                    <h2 className="black">Te presentamos hoop by Froma</h2>
                    <p className="mt-4 medium" style={{ fontSize: 18 }}>
                        La nueva forma de invertir tu dinero con mas flexibilidad, mayor retorno y lo mejor, sin plazos forzosos ni cantidades grandes para comenzar.
                    </p>
                </div>
                <div className="calculator mt-5">
                    <div className="calculator-title">
                        <h3 className="black" style={{ paddingTop: 6 }}>CALCÚLALO TÚ MISMO</h3>
                    </div>
                </div>
                <div className="w-100 h-auto text-center mt-5">
                    <h5 className="medium">Monto de inversión:</h5>
                </div>
                <div className="w-100 h-auto text-center">
                    <div className="slider">
                        <PrettoSlider
                            aria-label="Temperature"
                            defaultValue={30}
                            step={10000}
                            marks={amountMarks}
                            min={10000}
                            max={100000}
                            onChange={(e) => {
                                if (e.target.value !== amount) {
                                    setAmount(e.target.value);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="w-100 h-auto text-center mt-4">
                    <h5 className="medium">Tiempo de inversión:</h5>
                </div>
                <div className="w-100 h-auto text-center">
                    <div className="slider">
                        <PrettoSlider
                            aria-label="Temperature"
                            defaultValue={1}
                            marks={monthsMarks}
                            min={1}
                            max={3}
                            onChange={(e) => {
                                if (e.target.value !== months) {
                                    setMonths(e.target.value);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="w-100 h-auto text-center mt-4">
                    <h2 className="medium">RETORNO TOTAL:</h2>
                    <h2 className="black">{currencyFormat(total)}</h2>
                </div>
                <div className="w-100 h-auto text-center mt-4 mb-4">
                    <button className="invest-button medium" onClick={executeScroll}>Quiero invertir</button>
                </div>
            </div>
        </div>
    );
}

export default Calculator;
