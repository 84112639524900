import Header from "./components/Header";
import Main from "./components/Main";
import Calculator from "./components/Calculator";
import Form from "./components/Form";
import Footer from "./components/Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";


function App(props) {

  React.useEffect(() => {
  }, [])

  return (
    <div className="App">
      <Main />
      <Calculator />
      <Form />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={1500}
        closeOnClick
      />
    </div>
  );
}

export default App;
