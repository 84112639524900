import React from 'react';

function Footer() {
    return (
        <footer className="text-center text-white" style={{ backgroundColor: '#f1f1f1' }}>
            <div className="container pt-2">
                <section className="mb-2">
                    <a className="btn btn-link btn-floating btn-lg text-dark m-1"
                        href="https://www.instagram.com/hoopbyfroma/?hl=es"
                        target="_blank"
                        role="button"
                        data-mdb-ripple-color="dark">
                        <i class="fab fa-facebook-f" style={{ color: 'rgba(193,0,236,1)' }}></i>
                    </a>
                    <a className="btn btn-link btn-floating btn-lg text-dark m-1"
                        href="https://www.instagram.com/hoopbyfroma/?hl=es"
                        target="_blank"
                        role="button"
                        data-mdb-ripple-color="dark">
                        <i class="fab fa-instagram" style={{ color: 'rgba(193,0,236,1)' }}></i>
                    </a>
                </section>
            </div>
            <div className="text-center text-dark p-3" style={{ backgroundColor: '#FFFFFF' }}>
                {'2021 Hoop'}
            </div>
        </footer>
    );
}

export default Footer;
